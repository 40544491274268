/* eslint-disable object-shorthand */
import { api } from 'src/boot/axios'
import { useUserStore } from 'stores/user'

export function loginReq(data) {
  return api({
    url: 'user/webLogin',
    method: 'post',
    mode: 'cors',
    data: {
      Account: data.username,
      Password: data.password,
      DeviceId: 'web',
      Platform: data.platform,
      DeviceName: data.deviceName
    }
  })
}

export function loginRep(data) {
  if (!data) return
  branch.setIdentity(data.Uid)

  return {
    // data: data,
    email: data.Email,
    token: data.Token,
    type: data.Type,
    uid: data.Uid,
    vipTimeLeft: data.VipTimeLeft,
    whiteList: data.WhiteList
  }
}

export function getUserInfoReq(data) {
  return api({
    url: 'user/info',
    method: 'post',
    headers: {
      DeviceId: data
    },
    data: {}
  })
}

export function getUserInfoRep(data) {
  if (!data) return
  return {
    data: data.data
  }
}

export function registerReq(data) {
  return api({
    url: 'user/register',
    method: 'post',
    data: {
      Account: data.username,
      Password: data.password,
      DeviceId: data.deviceId,
      Platform: data.platform,
      DeviceName: data.deviceName,
      InviteUid: data.inviteUid
    }
  })
}

export function registerRep(data) {
  if (!data) return
  return {}
}

export function setInviterReq(pid) {
  return api({
    url: 'user/setInviter',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {
      Pid: pid
    }
  })
}

export function inviterExistsReq() {
  return api({
    url: 'user/inviterExists',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {}
  })
}

export function numberOfPeopleInviterReq() {
  return api({
    url: 'user/numberOfPeopleInviter',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {}
  })
}

export function changePasswordReq(data) {
  return api({
    url: 'user/changePassword',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {
      OldPassword: data.oldPassword,
      NewPassword: data.newPassword
    }
  })
}

export function resetPasswordReq(data) {
  return api({
    url: 'user/forgetPassword',
    method: 'post',
    data: {
      Token: data.token,
      Password: data.password
    }
  })
}
