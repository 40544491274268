import { Lang } from 'quasar'
import { boot } from 'quasar/wrappers'
import { createI18n } from 'vue-i18n'
import messages from 'src/i18n'
import { useUserStore } from 'src/stores/user'

/**
 * parse lang from header Accept-Language, and sort by priority
 * @param {string} acceptLanguage
 * @returns {string[]} lang[]
 */
function parseAcceptLang(acceptLanguage) {
  if (!acceptLanguage) return []

  return acceptLanguage
    .split(',')
    .map((langWithPriority) => {
      const [lang, priority] = langWithPriority.split(';q=')
      return {
        lang: lang.trim(),
        q: priority ? parseFloat(priority) : 1.0
      }
    })
    .sort((a, b) => b.q - a.q)
    .map((item) => item.lang)
}

/**
 * find best matching lang
 * @param {Object} options
 * @param {Record<string, Object>} options.messages supported language map
 * @param {string} options.queryLang
 * @param {string[]} options.acceptLang
 * @param {string} options.fallback
 * @returns {string} lang
 */
function findLang({ messages, queryLang, acceptLang, fallback }) {
  if (queryLang && messages[queryLang]) {
    return queryLang
  }
  return acceptLang.find((lang) => messages[lang]) ?? fallback
}

export default boot(async ({ app, ssrContext, urlPath, store }) => {
  const lang = findLang({
    messages,
    queryLang: process.env.SERVER
      ? [].concat(ssrContext.req.query.lang)[0]
      : new URL(urlPath, process.env.W_REDIRECT_URL).searchParams.get('lang'),
    acceptLang: process.env.SERVER
      ? parseAcceptLang(ssrContext.req.headers['accept-language'])
      : navigator.languages,
    fallback: 'en-US'
  })
  const i18n = createI18n({
    locale: lang,
    legacy: false,
    messages,
    missing: () => {
      return 'null'
    }
  })

  // sync lang
  const userStore = useUserStore(store)
  userStore.setLang(i18n.global.locale.value)

  // sync quasar
  await import('quasar/lang/' + i18n.global.locale.value).then((lang) => {
    Lang.set(lang.default, ssrContext)
  })

  // Set i18n instance on app
  app.use(i18n)
})
