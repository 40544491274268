import { defineStore } from 'pinia'
import defaultRoutes, {
  constantRoutes,
  notFoundRoutes
} from 'src/router/routes'
import {
  loginReq,
  loginRep,
  getUserInfoReq,
  getUserInfoRep
} from 'src/api/login'
import {
  getToken,
  getUserInfo,
  getUserLoginInfo,
  removeToken,
  removeLoginInfo,
  setToken,
  setUserLoginInfo,
  removeUserInfo,
  setUserInfo
} from 'src/utils/auth'

export const useUserStore = defineStore('user', {
  state: () => ({
    token: null,
    userLoginInfo: null,
    userInfo: null,
    routes: [],
    roles: [],
    packageId: 1,
    lang: ''
  }),
  getters: {
    userName(state) {
      if (state.userLoginInfo) {
        return state.userLoginInfo.name
      }
    }
  },
  actions: {
    resume() {
      if (process.env.SERVER) return
      this.token = getToken()
      this.userLoginInfo = getUserLoginInfo()
      this.userInfo = getUserInfo()
    },
    login(param) {
      return new Promise((resolve, reject) => {
        loginReq(param)
          .then((response) => {
            const rep = loginRep(response.data)
            this.token = rep.token
            this.userLoginInfo = Object.assign(rep, {
              name: param.username,
              deviceId: param.deviceId,
              platform: param.platform
            })
            setToken(rep.token)
            setUserLoginInfo(this.userLoginInfo)
            resolve({ token: rep.token, userLoginInfo: this.userLoginInfo })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    logout() {
      this.token = null
      this.userLoginInfo = null
      this.userInfo = null
      removeToken()
      removeLoginInfo()
      removeUserInfo()
    },
    setPackageId(id) {
      this.packageId = id
    },
    setRoles(role) {
      this.token = role.token
      return getUserInfoReq(role.deviceId).then((res) => {
        const rep = getUserInfoRep(res)
        this.userLoginInfo = Object.assign(rep.data, {
          account: rep.Account,
          deviceId: role.deviceId
        })
        setToken(role.token)
        setUserLoginInfo(this.userLoginInfo)
        this.updateUserInfo(rep.data)
      })
    },
    updateUserInfo(data) {
      this.userInfo = data
      setUserInfo(data)
    },
    setLang(lang) {
      this.lang = lang
    },
    setRoutes() {
      return new Promise((resolve) => {
        const routes = []
        const rolesMap = {}
        this.roles.forEach((role) => {
          rolesMap[role.nodePath] = role
        })

        for (const route of defaultRoutes) {
          const childMap = {}
          const children = []
          for (const child of rolesMap[route.path]?.children) {
            childMap[child.nodePath] = child.children.map(
              (node) => node.nodePath
            )
          }
          // 菜单子路由
          for (const child of route.children) {
            if (Object.keys(childMap).includes(child.path)) {
              // 路由按钮权限
              child.meta.roles = childMap[child.path]
              children.push(child)
            }
          }
          route.children = children
        }
        this.routes = constantRoutes.concat(routes).concat(notFoundRoutes)
        resolve()
      })
    }
  }
})
