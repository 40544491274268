import { useQuasar } from 'quasar'

/**
 * @description get user system name
 * @returns {String} device
 */
export const getUA = () => {
  const $q = useQuasar()
  let device = 'Unknown'
  const ua = {
    'Generic Linux': /Linux/i,
    Android: /Android/i,
    BlackBerry: /BlackBerry/i,
    Bluebird: /EF500/i,
    'Chrome OS': /CrOS/i,
    Datalogic: /DL-AXIS/i,
    Honeywell: /CT50/i,
    iPad: /iPad/i,
    iPhone: /iPhone/i,
    iPod: /iPod/i,
    macOS: /Macintosh/i,
    Windows: /IEMobile|Windows/i,
    Zebra: /TC70|TC55/i
  }
  Object.keys(ua).map((v) => $q.platform.userAgent.match(ua[v]) && (device = v))
  return device
}

export const getDeviceName = () => {
  const $q = useQuasar()
  return $q.platform.userAgent.split('(')[1].split(')')[0]
}

/**
 * @description validate device is android or ios
 * @returns {Boolean} isMobile
 */
export const isMobile = () => {
  const device = getUA()
  return device === 'Android' || device === 'iPhone' || device === 'iPad'
}

/**
 *
 * @param {String} str
 * @returns
 */
export const regExpCheckIsOnlyAlp = (str) => {
  let regExp = new RegExp(/^[a-zA-Z]/)
  return regExp.test(str)
}

/**
 * @example base64Encode("a Ā 𐀀 文 🦄") === "YSDEgCDwkICAIOaWhyDwn6aE"
 * @param {String} str utf8 string
 * @returns {String} base64 string
 */
export function base64Encode(str) {
  if (!str) return ''
  const bytes = new TextEncoder().encode(str)
  const binString = Array.from(bytes, (byte) =>
    String.fromCodePoint(byte)
  ).join('')
  const base64 = btoa(binString)
  return base64
}

/**
 * @example base64Decode("YSDEgCDwkICAIOaWhyDwn6aE") === "a Ā 𐀀 文 🦄"
 * @param {String} str base64 string
 * @returns {String} utf8 string
 */
export function base64Decode(str) {
  try {
    if (!str) return ''
    const binString = atob(str)
    const bytes = Uint8Array.from(binString, (m) => m.codePointAt(0))
    const utf8 = new TextDecoder().decode(bytes)
    return utf8
  } catch (e) {
    return null
  }
}

/**
 * @param {string|number|HTMLElement} source
 */
export function copy(source) {
  let text

  if (
    typeof source === 'string' ||
    (typeof source === 'number' && !isNaN(source) && isFinite(source))
  ) {
    text = '' + source
  } else if (source instanceof HTMLElement) {
    text = source.innerText
  } else {
    return false
  }

  const el = document.createElement('textarea')
  el.setAttribute('readonly', true)
  el.setAttribute('contenteditable', true)
  el.style.position = 'fixed'
  el.value = text
  document.body.appendChild(el)
  el.focus()
  el.select()

  const range = document.createRange()
  range.selectNodeContents(el)
  const sel = window.getSelection()
  sel.removeAllRanges()
  sel.addRange(range)
  el.setSelectionRange(0, text.length)

  document.execCommand('copy')
  document.body.removeChild(el)

  return true
}

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export function usd(value) {
  return USDollar.format(value).slice(1)
}

export function maskUsername(str = '') {
  if (str.length < 5) return str
  return str.replace(/^(.{2})(.+?)(.{2})$/, (match, start, middle, end) => {
    return start + '*'.repeat(middle.length) + end
  })
}

const isUsefulMsg = (msg = '') => msg && msg !== 'null'

export function findUsefulMsg(...messages) {
  return messages.find(isUsefulMsg) ?? messages[0]
}

export function url(
  path = '/',
  { query = {}, base = process.env.W_REDIRECT_URL } = {}
) {
  const isValidBase =
    base === '' || base.startsWith('/') || /^[a-z]+:\/\//.test(base)
  if (!isValidBase) throw new Error(`Invalid base: "${base}"`)

  const basePlaceholder = 'https://template.io'
  const url = new URL(path, basePlaceholder)
  Object.keys(query).forEach((key) => {
    if (query[key] === undefined) return
    if (query[key] === null) {
      url.searchParams.delete(key)
    } else if (Array.isArray(query[key])) {
      url.searchParams.delete(key)
      query[key].forEach((v) => url.searchParams.append(key, v))
    } else {
      url.searchParams.set(key, query[key])
    }
  })
  return url.toString().replace(basePlaceholder, base.replace(/\/$/, ''))
}

/** [useMeta](https://quasar.dev/quasar-plugins/meta#composition-api)*/

/**
 * @param {string} title
 * @returns {`${title} - GoDuck` | 'GoDuck'}
 */
export function metaTitleTemplate(title = '') {
  return isUsefulMsg(title) ? `${title} - GoDuck` : 'GoDuck'
}

/**
 * [頁面 meta 語系標記](https://developers.google.com/search/docs/specialty/international/localized-versions?hl=zh-cn#html)
 * @param {string} path
 * @param {string[]} availableLocales
 * @param {string} fallbackLocale
 * @returns {Record<string, { rel: string, hreflang: string, href: string }>}
 */
export function metaLinkLocale(
  path = '/',
  availableLocales = []
  // fallbackLocale = ''
) {
  return availableLocales.reduce(
    (map, lang) => {
      map[lang] = {
        rel: 'alternate',
        hreflang: lang,
        href: url(path, { query: { lang } })
      }
      return map
    },
    {
      'x-default': {
        rel: 'alternate',
        hreflang: 'x-default',
        href: url(path, { query: { lang: null } })
      },
      api: {
        rel: 'preconnect',
        crossorigin: 'use-credentials',
        href: process.env.BASE_API
      }
    }
  )
}
